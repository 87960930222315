import { FabAIObservables } from '@copilot/mfa-communication';

const handleAIChatView = async () => {
  const chatExpansionStateSubscription =
    FabAIObservables.chatExpansionState$.subscribe(expansionState => {
      const aiContent = document.getElementById('ai-application-container');
      const mainContent = document.getElementById('main-content-container');

      if (aiContent && mainContent) {
        if (
          expansionState === FabAIObservables.ChatExpansionStateType.COLLAPSED
        ) {
          requestAnimationFrame(() => {
            mainContent.style.width = '100%';
            mainContent.style.opacity = '1';
            mainContent.style.visibility = 'visible';
            aiContent.style.width = '0%';
            aiContent.style.opacity = '0';
            aiContent.style.visibility = 'hidden';
          });
        } else if (
          expansionState === FabAIObservables.ChatExpansionStateType.THIRD
        ) {
          requestAnimationFrame(() => {
            mainContent.style.flex = '1';
            mainContent.style.opacity = '1';
            mainContent.style.visibility = 'visible';
            aiContent.style.width = '368px';
            aiContent.style.opacity = '1';
            aiContent.style.visibility = 'visible';
          });
        } else if (
          expansionState === FabAIObservables.ChatExpansionStateType.FULL_SCREEN
        ) {
          requestAnimationFrame(() => {
            mainContent.style.width = '0%';
            mainContent.style.opacity = '0';
            mainContent.style.visibility = 'hidden';
            aiContent.style.width = '100%';
            aiContent.style.opacity = '1';
            aiContent.style.visibility = 'visible';
          });
        }
      }
    });

  return () => {
    chatExpansionStateSubscription.unsubscribe();
  };
};

export default handleAIChatView;
