import { DataDogConfig } from '../@types';

export const dataDogConfig: DataDogConfig = {
  applicationId: process.env.DATA_DOG_APPLICATION_ID,
  clientToken: process.env.DATA_DOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: process.env.DATA_DOG_SERVICE,
  env: process.env.DATA_DOG_ENV,
  version: '1.0.0',
  sampleRate: parseInt(process.env.DATA_DOG_SAMPLE_RATE),
  replaySampleRate: parseInt(process.env.DATA_DOG_SAMPLE_RATE),
  trackInteractions: true,
  allowedTracingOrigins: [
    /https:\/\/.*\.fabric\.inc/,
    /https:\/\/.*\.fabric\.zone/,
  ],
};

export enum SESSION_STORAGE_KEYS {
  ACCOUNT_ID = 'accountId',
  ACCESS_TOKEN = 'accessToken',
  ORG_DETAIL = 'orgDetail',
  TENANT_DETAIL = 'tenantDetail',
}

export const TENANT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const UNLEASH_FEATURE_FLAGS = {
  FEATURE_SELF_ONBOARDING: 'copilot-self-onboarding',
  FEATURE_L1_URL_CHANGES: 'copilot-authentication-url-update',
  FEATURE_CONSENT_FORM: 'copilot-authentication-consent',
  FEATURE_ANALYTICS_DASHBOARD: 'copilot-analytics-dashboard',
  FAB_AI_HOMEPAGE: 'fabai-home-page',
};
