import {
  registerApplication,
  start,
  addErrorHandler,
  getAppStatus,
  LOAD_ERROR,
} from 'single-spa';
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout';
import { datadogRum } from '@datadog/browser-rum';
import { dataDogConfig } from './utils/constants';
import sessionLifecycle from './auth/sessionLifecycle';
import * as FullStory from '@fullstory/browser';

const copilotRootConfig = () => {
  const routes = constructRoutes(document.querySelector('#single-spa-layout'));
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name);
    },
  });
  const layoutEngine = constructLayoutEngine({ routes, applications });
  const isEURegion = process.env.IS_EU_REGION === 'true';
  const fullStoryOrgId = process.env.FULLSTORY_ORG_ID;

  applications.forEach(registerApplication);
  layoutEngine.activate();
  datadogRum.init(dataDogConfig);
  if (!isEURegion && fullStoryOrgId) {
    FullStory.init({ orgId: process.env.FULLSTORY_ORG_ID });
  }
  datadogRum.startSessionReplayRecording();
  addErrorHandler(err => {
    if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
      System.delete(System.resolve(err.appOrParcelName));
    }
  });
  sessionLifecycle(datadogRum);
  start();
};

export default copilotRootConfig;
