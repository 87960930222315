import { fromEvent } from 'rxjs';

declare global {
  interface Window {
    onBreadcrumbUpdate: (cb) => unknown;
    updateBreadcrumb: (breadcrumbData) => void;
  }
}

const handleBreadcrumbs = () => {
  try {
    const BreadcrumbUpdateEvent = 'BreadcrumbUpdateEvent';
    const BreadcrumbObserver = fromEvent(window, BreadcrumbUpdateEvent);

    let InitialSubscriptionValue = null;
    const breadcrumbSubscription = BreadcrumbObserver.subscribe(
      (value: CustomEvent) => {
        InitialSubscriptionValue = value.detail;
      }
    );

    window.onBreadcrumbUpdate = callback => {
      if (InitialSubscriptionValue !== null) {
        callback(InitialSubscriptionValue);
      }
      const subscription = BreadcrumbObserver.subscribe((value: CustomEvent) =>
        callback(value.detail)
      );
      return subscription;
    };

    window.updateBreadcrumb = breadcrumbData => {
      window.dispatchEvent(
        new CustomEvent(BreadcrumbUpdateEvent, {
          detail: breadcrumbData,
        })
      );
    };

    return () => {
      breadcrumbSubscription.unsubscribe();
      delete window.onBreadcrumbUpdate;
      delete window.updateBreadcrumb;
    };
  } catch (e) {
    console.warn('Error with breadcrumbs', e);
  }
};

export default handleBreadcrumbs;
