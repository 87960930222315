const showTemplateBasedOnPath = () => {
  const currentPath = window.location.pathname;

  const rootAppContainer = document.getElementById(
    'root-application-container'
  );
  if (rootAppContainer) {
    // Do not show the rootAppContainer template if we are on the login screen
    if (currentPath === '/auth/v2/login') {
      rootAppContainer.style.display = 'none';
    } else {
      rootAppContainer.style.display = 'grid';
    }
  }
};

const handleRootAppContainer = () => {
  // Listen for popstate events (back/forward button)
  window.addEventListener('popstate', showTemplateBasedOnPath);

  // sent to a Window when the browser displays the window's document due to navigation
  window.addEventListener('pageshow', showTemplateBasedOnPath);

  // Call the function once on page load to set the initial state
  showTemplateBasedOnPath();

  return () => {
    window.removeEventListener('popstate', showTemplateBasedOnPath);
    window.removeEventListener('pageshow', showTemplateBasedOnPath);
  };
};

export default handleRootAppContainer;
