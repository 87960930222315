import api from '../auth/api';
import { navigateToUrl } from 'single-spa';
import {
  ASSISTANT_ROUTE,
  SESSION_STORAGE_KEYS,
  TENANT_STATUS,
  UNLEASH_FEATURE_FLAGS,
} from './constants';
import { client } from '../auth/sessionLifecycle';

export const forceRedirectDisabledRoute = [
  '/auth/login',
  '/onboarding/signup',
  '/',
  '/auth/v2/login',
  '/home/activity',
];

export const accountDetailsRoute = '/home/account-details';
export const adminActivityRoute = '/home/activity';

export const getHomePageRoute = (isFabAIHomePageEnabled: boolean) => {
  if (isFabAIHomePageEnabled) {
    return ASSISTANT_ROUTE;
  } else {
    return adminActivityRoute;
  }
};

export const navigateToHomeActivity = async (
  tenant?: {
    status: string;
  },
  isFabAIHomePageEnabled?: boolean
) => {
  const redirect_url = await localStorage.getItem('redirect_uri');
  if (tenant?.status !== TENANT_STATUS.ACTIVE) {
    navigateToUrl(accountDetailsRoute);
  } else if (
    redirect_url &&
    forceRedirectDisabledRoute.indexOf(redirect_url) < 0
  ) {
    localStorage.removeItem('redirect_uri');
    navigateToUrl(redirect_url);
  } else {
    navigateToUrl(getHomePageRoute(isFabAIHomePageEnabled));
  }
};

export const redirectAndRemoveLink = () => {
  const redirectUrl = localStorage.getItem('redirect_uri');
  if (redirectUrl && forceRedirectDisabledRoute.indexOf(redirectUrl) < 0) {
    localStorage.removeItem('redirect_uri');
    navigateToUrl(`${window.location.origin}${redirectUrl}`);
  } else {
    return;
  }
};
export default async function SelfOnboardingDataEvent() {
  window.addEventListener(
    'fetchSelfOnboardingData',
    async (event: CustomEvent) => {
      const { eventFromHeader, consented, isConsentFormEnabled } = event.detail;

      const isFabAIHomePageEnabled = client.isEnabled(
        UNLEASH_FEATURE_FLAGS.FAB_AI_HOMEPAGE
      );

      try {
        const accountId = sessionStorage.getItem(
          SESSION_STORAGE_KEYS.ACCOUNT_ID
        );
        const { data: orgDetails } = await api.devx.post(
          `tenants/${accountId}/actions/get-organization`
        );
        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.ORG_DETAIL,
          JSON.stringify(orgDetails)
        );
        const { data: tenant } = await api.devx.get(
          `/organizations/${orgDetails?.id}/tenants/${accountId}`
        );
        sessionStorage.setItem(
          SESSION_STORAGE_KEYS.TENANT_DETAIL,
          JSON.stringify(tenant)
        );

        if (!isConsentFormEnabled) {
          navigateToHomeActivity(tenant, isFabAIHomePageEnabled);
        }

        if (isConsentFormEnabled) {
          if (consented) {
            navigateToHomeActivity(tenant, isFabAIHomePageEnabled);
          } else {
            navigateToUrl('/auth/terms');
          }
        }

        if (eventFromHeader) {
          window.location.reload();
        }
      } catch (err) {
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.ORG_DETAIL);
        if (!isConsentFormEnabled) {
          navigateToUrl(getHomePageRoute(isFabAIHomePageEnabled));
        } else {
          if (consented) {
            navigateToUrl(getHomePageRoute(isFabAIHomePageEnabled));
          } else {
            navigateToUrl('/auth/terms');
          }
        }
        if (eventFromHeader) {
          window.location.reload();
        }
      }
    }
  );
}

export const validateAndSetForceRedirect = () => {
  if (forceRedirectDisabledRoute.indexOf(window.location.pathname) < 0) {
    localStorage.setItem(
      'redirect_uri',
      `${window.location.pathname}${window.location.search}`
    );
  }
};
